<template>
    <van-popup class="editNicknamePopup" @close="onClose" v-model="show" get-container="body">
        <div class="title">修改昵称</div>
        <div class="form">
            <div class="item">
                <input @change="onNicknameChange" v-model="nickname" placeholder="请输入" />
                <span class="length">{{ nickname.length }}/10</span>
            </div>
        </div>
        <div class="opts">
            <van-button plain @click="onClose">取消</van-button>
            <van-button class="submit-btn" @click="onSubmit">保存</van-button>
        </div>
    </van-popup>
</template>
<script>
import { userInfoMod } from '@/api/userApi.js'
import { mapGetters } from 'vuex'

export default {
    name: 'editNicknamePopup',
    props: ['isShow'],
    computed: { ...mapGetters(['userInfo']) },

    data() {
        return {
            show: false,
            nickname: '',
        }
    },
    watch: {
        isShow: function (isShow) {
            this.show = isShow
            this.nickname = this.userInfo.nickname || ''
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
        onNicknameChange() {
            this.nickname = this.nickname.substr(0, 10)
        },
        onSubmit() {
            if (!this.nickname) {
                return this.$toast('请输入用户昵称')
            }
            userInfoMod(this.nickname).then(() => {
                this.show = false
                this.$toast.success('修改成功')
                this.$store.dispatch('user/updateUserInfo', {
                    key: 'nickname',
                    val: this.nickname,
                })
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.editNicknamePopup {
    width: 360px;
    border-radius: 4px;
    .title {
        font-size: 20px;
        color: #33312f;
        padding: 32px;
        font-weight: 700;
        text-align: center;
    }
    .form {
        .item {
            width: 320px;
            height: 36px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            margin-bottom: 16px;
        }
        input {
            width: 80%;
            height: 24px;
            font-size: 15px;
            color: #33312f;
            line-height: 24px;
            padding: 6px 30px 6px 12px;
        }
        .length {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c0c4cc;
            line-height: 14px;
        }
    }
    .opts {
        display: flex;
        justify-content: space-around;
        padding: 16px 32px 24px;
        .van-button {
            width: 100px;
            height: 36px;
            border-radius: 4px;
            font-size: 14px;
            margin: 0 auto;
        }
        .submit-btn {
            border: none;
            background: $theme-color;
        }
    }
}

@media screen and (max-width: 720px) {
    .editNicknamePopup {
        width: 280px;
        .title {
            font-size: 16px;
            padding: 20px;
        }
        .form {
            .item {
                width: 240px;
                height: 32px;
                margin-bottom: 12px;
            }
            input {
                width: 80%;
                height: 22px;
                font-size: 14px;
                color: #33312f;
                line-height: 24px;
                padding: 4px 30px 4px 12px;
            }
            .code-btn {
                width: 72px;
                font-size: 12px;
            }
        }
        .opts {
            padding: 12px 24px 16px;
            .van-button {
                width: 80px;
                height: 32px;
            }
        }
    }
}
</style>
